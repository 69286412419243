import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useOnLocationChange from '~/hooks/useOnLocationChange';
import { Box, CssBaseline } from '@mui/material';
import SideMenu from './SideMenu';

const Layout = () => {
	const styles = useStyles();
	const { i18n } = useTranslation();

	useOnLocationChange(() => {
		window.scrollTo(0, 0);
	});
	return (
		<Box sx={styles.layout}>
			<CssBaseline />
			<SideMenu />
			<Box key={i18n.language} sx={styles.main} component='main'>
				<Outlet />
			</Box>
		</Box>
	);
};

const useStyles = () => ({
	layout: {
		display: 'flex',
		minHeight: '100%',
	},
	main: {
		flexGrow: 1,
		p: 3,
	},
});

export default Layout;
