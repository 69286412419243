export const DRAWER_WIDTH = 240;
export const LANGUAGES = ['zh-TW'];
export const PERMISSION = {
	CREATE: 'CREATE',
	EDIT: 'EDIT',
	VIEW: 'VIEW',
	DELETE: 'DELETE',
};
export const ROWS_PER_PAGE = 25;

export enum ORDER_ENUM {
	asc = 'asc',
	desc = 'desc',
}
