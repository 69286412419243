import { lazy } from 'react';

const OrganizationStructurePage = lazy(
	() =>
		import(
			'./OrganizationStructure/OrganizationStructurePage' /* webpackChunkName: 'Resource' */
		)
);

const ResourceListPage = lazy(
	() => import('./ResourceListPage' /* webpackChunkName: 'Resource' */)
);

const ResourceCreatePage = lazy(
	() => import('./ResourceCreatePage' /* webpackChunkName: 'Resource' */)
);

const ResourceEditPage = lazy(
	() => import('./ResourceEditPage' /* webpackChunkName: 'Resource' */)
);

export {
	OrganizationStructurePage,
	ResourceListPage,
	ResourceCreatePage,
	ResourceEditPage,
};
