import type { RouteObject } from 'react-router-dom';
import Layout from '~/components/Layout';
import RequireAuth from '~/components/RequireAuth';
import DashboardPage from '~/pages/DashboardPage';
import * as ResourcePaths from '~/pages/Resource';
import * as LoginPaths from '~/pages/Login';
import NotFoundPage from '~/pages/NotFoundPage';

const routes: RouteObject[] = [
	{
		path: '/',
		element: (
			<>
				<Layout />
			</>
		),
		children: [
			{
				path: '/',
				element: (
					<RequireAuth>
						<DashboardPage />
					</RequireAuth>
				),
			},
			{
				path: '/resource',
				children: [
					{
						path: '/resource/:resourceType/create',
						element: (
							<RequireAuth>
								<ResourcePaths.ResourceCreatePage />
							</RequireAuth>
						),
					},
					{
						path: '/resource/:resourceType/:id/view',
						element: (
							<RequireAuth>
								<ResourcePaths.ResourceEditPage readonly />
							</RequireAuth>
						),
					},
					{
						path: '/resource/:resourceType/:id',
						element: (
							<RequireAuth>
								<ResourcePaths.ResourceEditPage />
							</RequireAuth>
						),
					},
					{
						path: '/resource/v-organization-structure',
						element: (
							<RequireAuth>
								<ResourcePaths.OrganizationStructurePage />
							</RequireAuth>
						),
					},
					{
						path: '/resource/:resourceType',
						element: (
							<RequireAuth>
								<ResourcePaths.ResourceListPage />
							</RequireAuth>
						),
					},
				],
			},
			//  resource
		],
	},
	{
		path: '/login',
		element: <LoginPaths.LoginPage />,
	},
	{
		path: '*',
		element: (
			<RequireAuth>
				<NotFoundPage />
			</RequireAuth>
		),
	},
];

export default routes;
