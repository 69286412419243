import { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import '~/libraries/schema';
import routes from './routes';
import './App.css';

function App(): JSX.Element {
	const element = useRoutes(routes);
	return <Suspense fallback={null}>{element}</Suspense>;
}

export default App;
