import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Button } from '@ally-transport/mui';

const NotFoundPage = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				height: '100vh',
			}}
		>
			<Typography variant='h1' sx={{ mt: 2.5 }}>
				404
			</Typography>
			<Typography variant='h5'>{t('notFoundPage')}</Typography>
			<Typography
				variant='body2'
				sx={{ mt: 2.5, whiteSpace: 'pre-line', textAlign: 'center' }}
			>
				{t('notFoundPageDesc')}
			</Typography>
			<Button
				sx={{ mt: 5, minWidth: '320px' }}
				onClick={() => {
					navigate('/');
				}}
			>
				{t('back')}
			</Button>
		</Box>
	);
};

export default NotFoundPage;
