import axios, {
	AxiosRequestConfig,
	AxiosRequestHeaders,
	AxiosResponse,
	AxiosError,
} from 'axios';
import store from 'store2';
import { RESPONSE_ERROR_DETAIL } from '~/enums/responseData';
import { REFRESH_TOKEN_EXPIRED } from '~/enums/errorMessages';
import { getTokenData } from '~/libraries/jwt';

type PromiseQueue = {
	resolve: (value?: unknown) => void;
	reject: (reason?: unknown) => void;
}[];

const apiURL =
	process.env.ENV_TYPE === 'production'
		? 'https://api-portal-prod.allytransport.com.tw/ally-auth-service/api'
		: 'https://api-portal-stage.allytransport.com.tw/ally-auth-service/api';

let isRefreshing = false;

let queue: PromiseQueue = [];

const ajax = axios.create({
	baseURL: apiURL,
	headers: {
		'Content-Type': 'application/json',
	},
});
ajax.interceptors.request.use(interceptHeaders);
ajax.interceptors.response.use(successInterceptor, failureInterceptor);

function interceptHeaders(config: AxiosRequestConfig) {
	const headers: AxiosRequestHeaders = {
		'Content-Type': 'application/json',
		...config.headers,
	};

	// Authorization
	const authorization = store.get('token');
	if (authorization) {
		headers.Authorization = `Bearer ${authorization}`;
	}

	return {
		...config,
		headers,
	};
}

function successInterceptor(response: AxiosResponse) {
	return response;
}

async function requestRefreshToken(originalRequest: any): Promise<any> {
	try {
		isRefreshing = true;
		// 不使用ajax instance才不會進入 jwtHandler迴圈
		await fetchToken();
		const originalResponse = await ajax(originalRequest);
		resolveQueue();
		return originalResponse;
	} catch (error: any) {
		rejectQueue();
		const status = error?.response?.status;
		switch (status) {
			case 401:
				return Promise.reject(new Error(REFRESH_TOKEN_EXPIRED));
			default:
				break;
		}
	} finally {
		isRefreshing = false;
	}
}

function resolveQueue() {
	queue.forEach((p) => p.resolve());
	queue = [];
}

function rejectQueue() {
	queue.forEach((p) => p.reject());
	queue = [];
}

async function authTokenHandler(error: AxiosError) {
	const originalRequest = error.config;
	if (isRefreshing) {
		return new Promise((resolve, reject) => {
			queue.push({ resolve, reject });
		})
			.then(() => {
				return ajax(originalRequest);
			})
			.catch(Promise.reject);
	} else {
		return await requestRefreshToken(originalRequest);
	}
}

function failureInterceptor(error: AxiosError) {
	if (error.isAxiosError && error.response) {
		const { response } = error;
		switch (response.status) {
			case 401:
				if (
					response?.data?.detail ===
					RESPONSE_ERROR_DETAIL.PROVIDED_TOKEN_IS_NOT_VALID
				) {
					return authTokenHandler(error);
				} else {
					return Promise.reject(error);
				}
			case 500:
				return Promise.reject(error);
			default:
				return Promise.reject(response);
		}
	}
	return Promise.reject(error);
}

async function fetchToken() {
	const refreshToken = store.get('refreshToken');
	if (!refreshToken) throw new Error('No refresh token available');
	const tokenData = getTokenData();
	if (tokenData?.exp) {
		const now = new Date().getTime() / 1000;
		if (tokenData.exp - now > 30) {
			return store.get('token');
		}
	}
	const refreshUrl = `${apiURL}/refresh/auth_user`;
	const response = await axios.post(refreshUrl, {
		token: refreshToken,
	});
	const token = response?.data?.token;
	store.set('token', token);
	return token;
}

export default ajax;
