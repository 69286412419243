import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import store from 'store2';

const defaultLocale = 'zh-TW';

let locale = store('locale');
if (!locale) {
	store('locale', defaultLocale);
	locale = defaultLocale;
}

const initOptions: InitOptions = {
	load: 'currentOnly',
	lng: locale,
	ns: ['common', 'schema', 'ajax', 'login'],
	defaultNS: 'common',
	fallbackLng: [],
	debug: process.env.NODE_ENV !== 'production',
	keySeparator: false, // we do not use keys in form messages.welcome
	interpolation: {
		escapeValue: false, // react already safes from xss
		format: function (value, format) {
			if (value) {
				if (format === 'join') return value.join(', ');
			}
			return value;
		},
	},
	backend: {
		loadPath: '{{lng}}/{{ns}}',
		parse: (data) => JSON.parse(data),
		request: function (options, url, payload, callback) {
			import(`./locales/${url}.json`)
				.then((locale) => {
					callback(null, { data: JSON.stringify(locale.default), status: 200 });
				})
				.catch((err) => {
					callback(err, { status: 404, data: JSON.stringify({}) });
				});
		},
	},
};

i18n.use(HttpApi).use(initReactI18next).init(initOptions);

export default i18n;
