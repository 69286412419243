import React, { useMemo, useCallback } from 'react';
import store from 'store2';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';
import type { LinkProps as RouterLinkProps } from 'react-router-dom';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
	Drawer,
	Toolbar,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Divider,
	Link,
} from '@mui/material';
import { Button } from '@ally-transport/mui';
import { DRAWER_WIDTH } from '~/enums/app';

const SideMenu = () => {
	const { t } = useTranslation();
	const location = useLocation();
	const { data } = useSWR('/meta', { revalidateIfStale: false });
	const menuList = useMemo(() => {
		if (data) {
			return data.data;
		}
		return [];
	}, [data]);
	const logout = useCallback(() => {
		store.remove('token');
		store.remove('refreshToken');
		window.location.href = '/login';
	}, []);
	return (
		<Drawer
			sx={{
				width: DRAWER_WIDTH,
				flexShrink: 0,
				'& .MuiDrawer-paper': {
					width: DRAWER_WIDTH,
					boxSizing: 'border-box',
				},
			}}
			variant='permanent'
			anchor='left'
		>
			<Toolbar>
				<Button variant='outlined' onClick={logout}>
					{t('logout')}
				</Button>
			</Toolbar>
			<Divider />
			<List>
				{menuList.map((menu: any) => {
					const path = `/resource/${menu.resourceType}`;
					return (
						<Link
							key={menu.resourceType}
							underline='none'
							component={LinkBehavior}
							to={path}
						>
							<ListItem disablePadding>
								<ListItemButton
									selected={
										location.pathname === path ||
										location.pathname.includes(`${path}/`)
									}
								>
									<ListItemText primary={menu.title || menu.resourceType} />
								</ListItemButton>
							</ListItem>
						</Link>
					);
				})}
			</List>
		</Drawer>
	);
};

const LinkBehavior = React.forwardRef<any, RouterLinkProps>((props, ref) => (
	<RouterLink ref={ref} {...props} />
));

export default SideMenu;
